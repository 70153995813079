import React from 'react';
import cn from 'classnames';
import { LoginHeader } from '../LoginHeader';
import { SignUpForm } from './components/SignUpForm';
import { LoginFooter } from '../LoginFooter';

import styles from './SignUp.module.scss';

const FOLLOW_TYPE = 'signIn';

export function SignUp({onDone, setContentType, register, status, message}) {
  return (
    <div>
      <LoginHeader
        title="Sign Up"
        subtitle="Create an account to continue using our services easily"
        onClose={onDone}
      />
      {status
        ? <div className={cn(styles.message, styles[status])}>
          {message}
        </div>
        : <SignUpForm
          onSubmit={(values) => {
            register(values);
            // setContentType({contentType: FOLLOW_TYPE});
          }}
          setContentType={setContentType}/>
      }
      <LoginFooter
        showDivider={!status}
        footerText="Already have an account?"
        footerLink="Sign in"
        onChangeType={() => setContentType({contentType: FOLLOW_TYPE})}
      />
    </div>
  );
}
