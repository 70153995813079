import React, { useState } from 'react';
import Modal from 'react-modal';
import { HeaderNavigation } from '../../shared/components/HeaderNavigation';
import { MainNavigation } from '../../shared/components/MainHavigation';
import { Field, Form, Formik } from 'formik';
import style from './Settings.module.scss';
import { Input } from '../../shared/components/Input';
import './Settings.scss';
import close from '../../login/components/LoginHeader/img/close-icon.png';
import { useAuth } from '../../shared/auth';
import { useHistory } from 'react-router';

const styles = {
  content: {
    position: 'absolute',
    top: '50px',
    left: 0,
    bottom: 0,
    right: 0,
    width: '70%',
    height: '500px',
    margin: 'auto',
    padding: '40px 40px',
    background: '#FAFAFA',
    border: 'none',
    'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.25)',
    'border-radius': '5px',
  }
};

const passwordStyles = {
  content: {
    position: 'absolute',
    top: '50px',
    left: 0,
    bottom: 0,
    right: 0,
    width: '330px',
    height: '434px',
    margin: 'auto',
    padding: '40px 40px',
    background: '#FAFAFA',
    border: 'none',
    'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.25)',
    'border-radius': '5px',
  }
};

const passwordInitialValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};

export function Settings({ onSubmit, updateDetails }) {
  const { user } = useAuth();
  const history = useHistory();
  const { firstName, lastName, phone } = user || {};
  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    phone: phone || '',
  };
  const [modalIsOpen, setIsOpen] = useState(true);
  const [passwordIsOpen, setPasswordIsOpen] = useState(false);

  return (
    <div className="header">
      <HeaderNavigation/>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setIsOpen(false);
          history.push('/');
        }}
        style={styles}
      >
        <div>
          <div className={style.left}>
            <h1>Settings</h1>
            <h4>This information will be used to fill in the appropriate fields automatically and speed up the booking
              process. Your data will be stored in a safe place and will not be passed on to third parties.</h4>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                updateDetails(values)
                onSubmit();
                history.push('/');
              }}
            >
              {() => (
                <Form>
                  <div className={style.row}>
                    <Field
                      className={style.input}
                      type="firstName"
                      name="firstName"
                      figcaption="First Name"
                      component={Input}
                    />
                    <Field
                      className={style.input}
                      type="lastName"
                      name="lastName"
                      figcaption="Last Name"
                      component={Input}
                    />
                  </div>
                  <div className={style.row}>
                    <Field
                      className={style.input}
                      type="phone"
                      name="phone"
                      figcaption="Phone"
                      component={Input}
                    />
                  </div>
                  <div className={style.password}>
                    <a onClick={() => {
                      setPasswordIsOpen(true);
                      setIsOpen(false);
                    }}>Change Password</a>
                  </div>
                  <button className={style.button} type="submit">Save</button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={passwordIsOpen}
        style={passwordStyles}
      >
        <div>
          <h1>Change password</h1>
          <img
            className={style['close-icon']}
            onClick={() => {
              setPasswordIsOpen(false);
              setIsOpen(true);
            }}
            src={close}
            alt="close"
          />
          <Formik
            initialValues={passwordInitialValues}
            onSubmit={(values) => {
              updateDetails({ password: values.newPassword })
              onSubmit();
              history.push('/');
            }}
          >
            {() => (
              <Form>
                <Field
                  className={style.input}
                  type="currentPassword"
                  name="currentPassword"
                  figcaption="Current password"
                  component={Input}
                />
                <Field
                  className={style.input}
                  type="newPassword"
                  name="newPassword"
                  figcaption="New password"
                  component={Input}
                />
                <Field
                  className={style.input}
                  type="newPasswordConfirm"
                  name="newPasswordConfirm"
                  figcaption="Confirm new password"
                  component={Input}
                />
                <button className={style.button} type="submit">Save</button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <MainNavigation left/>
    </div>
  );
}
