import { connect } from 'react-redux';

import { TopHotels } from './TopHotels';
import { loadTopHotels, selectTopHotels } from '../../modules';

const mapDispatchToProps = dispatch => ({
  loadTopHotels: () => dispatch(loadTopHotels()),
});

const mapStateToProps = state => ({
  topHotels: selectTopHotels(state),
});

const Enhanced = connect(mapStateToProps, mapDispatchToProps)(TopHotels);

export { Enhanced as TopHotels };
