import React from 'react';
import { Field } from 'formik';

import { Pagination } from '../../../shared/components/Pagination';

import styles from './HotelsSearchFooter.module.scss';

export function HotelsSearchFooter(props) {
  const { totalPages, totalHotels, page } = props;

  const countFromPage = (page + 1) * 10;
  const countOfShown =
    countFromPage > totalHotels ? totalHotels : countFromPage;

  return (
    <div className={styles.wrapper}>
      <div className={styles.total}>
        {countOfShown} of {totalHotels} has shown
      </div>
      <Field name="page">
        {({ field, form }) => {
          return (
            <Pagination
              page={field.value}
              total={totalPages}
              onChange={page => {
                form.setFieldValue(field.name, page.selected);
                form.handleSubmit(form.values);
              }}
            />
          );
        }}
      </Field>
    </div>
  );
}
