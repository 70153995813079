import React from 'react';
import Loader from 'react-loader-spinner';

import styles from './Loader.module.scss';

export function LoaderUI() {
  return (
    <div className={styles.loader}>
      <Loader
        type="MutatingDots"
        color="#57BCDD"
        secondaryColor="#57BCDD"
        height={100}
        width={100}
        className={styles.loaderItem}
      />
    </div>
  );
}
