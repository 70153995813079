import React from 'react';
import cn from 'classnames';

import styles from './Input.module.scss';

export function Input({ field, form, figcaption, className, error, ...props }) {
  return (
    <div style={{ position: 'relative' }}>
      {figcaption && <figcaption>{figcaption}{error && <span className={styles.error}>{error}</span>}</figcaption>}

      <input
        {...field}
        {...props}
        className={cn(styles.input, className, {
          [styles['input--error']]: !!error,
        })}
      />
    </div>
  );
}
