import React from 'react';
import styles from './LoginHeader.module.scss';
import close from './img/close-icon.png';

export function LoginHeader({ title, subtitle, onClose }) {
  return (
    <div>
      <h1 className={styles.title}>{title}</h1>
      <h4>{subtitle}</h4>
      <img className={styles['close-icon']} onClick={onClose} src={close} alt="close" />
    </div>
  )
}
