import React from 'react';

import { Pagination } from '../../../shared/components/Pagination';

import styles from './BookingSearchFooter.module.scss';

export function BookingSearchFooter(props) {
  const { totalPages, totalElements, page, onChange } = props;

  const countFromPage = (page + 1) * 10;
  const countOfShown =
    countFromPage > totalElements ? totalElements : countFromPage;

  return (
    <div className={styles.wrapper}>
      <div className={styles.total}>
        {countOfShown} of {totalElements} has shown
      </div>
      <Pagination
        page={page}
        total={totalPages}
        onChange={({ selected } = {}) => onChange(selected)}
      />
    </div>
  );
}
