import React, { useMemo } from 'react';

import { HotelCard } from '../HotelCard';

import styles from './HotelsList.module.scss';

export function HotelsList(props) {
  const { hotels, book } = props;

  const list = useMemo(() => {
    return hotels.map(hotel => (
        <HotelCard key={hotel.code} hotel={hotel} book={book} />
    ))
  }, [hotels]);

  if (!hotels.length) return null;

  return <div className={styles.list}>{list}</div>;
}
