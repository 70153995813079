import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Input } from '../../../../../shared/components';

import styles from '../../../common.module.scss';

function validate(values) {
  const errors = {};

  if (!values.email?.trim()) {
    errors.email = 'Required';
  }

  if (!values.password?.trim()) {
    errors.password = 'Required';
  }

  return errors;
}

export function SignInForm({ onSubmit }) {
  return (
    <div>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={(values) => {
          onSubmit(values)
        }}
        validate={validate}
      >
        {({ errors, touched }) => (
          <Form>
            <Field
              error={touched.email && errors.email}
              className={styles.input}
              type="email"
              name="email"
              figcaption="E-mail"
              component={Input}
            />
            <Field
              error={touched.password && errors.password}
              className={styles.input}
              type="password"
              name="password"
              figcaption="Password"
              component={Input}
            />
            <button className={styles.button} type="submit">Sign in</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
