import { connect } from 'react-redux';
import { HotelsSearchHeader } from './HotelsSearchHeader';
import { selectHotels, selectTotalHotels } from '../../modules';

const mapStateToProps = state => ({
  hotels: selectHotels(state),
  totalHotels: selectTotalHotels(state),
});

const Enhanced = connect(mapStateToProps, null)(HotelsSearchHeader);

export { Enhanced as HotelsSearchHeader };
