import React from 'react';
import { Field } from 'formik';

import map from './img/map.svg';
import arrows from './img/arrows.svg';

import styles from './HotelsSearchHeader.module.scss';

export function HotelsSearchHeader(props) {
  const { hotels, totalHotels } = props;

  if (!hotels.length) return null;

  const hotelsCopy = hotels.slice();

  const minAmount = hotelsCopy.sort((a, b) => a.minRate - b.minRate)[0].minRate;
  const destination = hotels[0].destinationName;

  return (
    <div className={styles.wrapper}>
      <span className={styles['title-wrap']}>
        <span className={styles.title}>
          {totalHotels} Hotels in {destination} From {minAmount}
        </span>
        <span className={styles.currency}>EUR</span>
      </span>

      <span className={styles.map__container}>
        <img src={map} alt="Map icon" className={styles['icon-map']} />
        <span className={styles.currency}>Show Map</span>
      </span>
      <span>
        <Field name="sort">
          {({ field, form }) => {
            return (
              <button
                className={styles.sort__button}
                type="button"
                onClick={() => {
                  form.setFieldValue(field.name, field.value === 'ASC' ? 'DESC' : 'ASC');
                  form.handleSubmit(form.values);
                }}
              >
                <span className={styles.sort__button__label}>Price</span>
                <img src={arrows} alt="Sort" />
              </button>
            );
          }}
        </Field>
      </span>
    </div>
  );
}
