export const debounce = (fn, delay = 500, immediate = false) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    if (immediate) {
      fn(...args);
      return;
    }

    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
};
