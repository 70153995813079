import React, {useCallback} from 'react';
import { Field, FieldArray } from 'formik';

import { EMPTY_FILTER } from '../../../containers/Hotels/Hotels';
import { Checkbox, ReviewsItems } from '../../../shared/components';
import { RadioGroup } from '../../../shared/components/RadioGroup';

import styles from './HotelsSearchFilter.module.scss';
import { PlacesAutocomplete } from '../../../shared/components/PlacesAutocomplete';
import { debounce } from '../../../shared/utils/debounce';

export function HotelsSearchFilter() {
  const onHandleChange = (renderProps, index) => value => {
    const idx = renderProps.form.values.filter[index].values.indexOf(value);
    if (idx >= 0) {
      renderProps.remove(idx);
      return renderProps.form.handleSubmit(renderProps.form.values);
    }
    renderProps.push(value);
    return renderProps.form.handleSubmit(renderProps.form.values);
  };

  const onHandleBooleanChange = (setFieldValue, name, value, form) => () => {
    if (value.length) {
      setFieldValue(name, []);
    } else {
      setFieldValue(name, [true]);
    }
    form.handleSubmit(form.values);
  };

  const isChecked = (renderProps, index, value) => {
    return renderProps.form.values.filter[index].values.includes(value);
  };

  const onResetForm = form => () => {
    if (form.values.filter !== form.initialValues.filter) {
      form.setFieldValue('filter', EMPTY_FILTER);
      form.handleSubmit(form.values);
    }
  };

  const debouncedChange = useCallback(debounce((hotelName, form, name, setFieldValue) => {
      setFieldValue(name, [hotelName]);
      form.handleSubmit(form.values);
  }), [])

  return (
    <FieldArray
      name="filter"
      validateOnChange={false}
      validateOnBlur={false}
      render={formProps => {
        return (
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <span className={styles['header__title']}>Filter By</span>
              <button
                className={styles['header__btn']}
                type="button"
                onClick={onResetForm(formProps.form)}
              >
                Reset filters
              </button>
            </div>
            <div className={styles.group} style={{ width: '100%' }}>
              <div className={styles['group__title']}>Hotel Name</div>
              <Field name="filter[8].values">
                {({ field: { name }, form: { setFieldValue }, form }) => {
                  return (
                    <input
                      type="text"
                      style={{
                        height: '50px',
                        width: '100%',
                        borderRadius: '5px',
                        backgroundColor: '#E9EEF5',
                        border: 'none',
                        outline: 'none',
                        boxSizing: 'border-box',
                        padding: '0px 0px 0px 10px',
                      }}
                      placeholder="Enter Hotel Name"
                      onChange={event => {
                          const str = event.target.value;
                          debouncedChange(str, form, name, setFieldValue);
                      }}
                    />
                  );
                }}
              </Field>
            </div>
            <div className={styles.group} style={{ width: '100%' }}>
              <div className={styles['group__title']}>Close To</div>
              <Field name={`filter[6].values`}>
                {({ field: { name }, form: { setFieldValue }, form }) => {
                  return (
                    <PlacesAutocomplete
                      onChange={values => {
                        setFieldValue(name, [...values, 5]);
                        form.handleSubmit(form.values);
                      }}
                      style={{
                        height: '50px',
                        width: '97%',
                        padding: '0px 0 0 10px',
                        border: 'none',
                        borderRadius: '5px',
                        backgroundColor: '#E9EEF5',
                      }}
                    />
                  );
                }}
              </Field>
            </div>
            <FieldArray
              name={`filter[0].values`}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {renderProps => {
                return (
                  <div className={styles.group}>
                    <div className={styles['group__title']}>Meals</div>

                    <Checkbox
                      id="BREAKFAST"
                      className={styles['group__item']}
                      onChange={onHandleChange(renderProps, 0)}
                      value="BREAKFAST"
                      checked={isChecked(renderProps, 0, 'BREAKFAST')}
                    >
                      Breakfast included
                    </Checkbox>
                    <Checkbox
                      className={styles['group__item']}
                      id="HALF"
                      onChange={onHandleChange(renderProps, 0)}
                      value="HALF"
                      checked={isChecked(renderProps, 0, 'HALF')}
                    >
                      Half board
                    </Checkbox>
                    <Checkbox
                      className={styles['group__item']}
                      id="FULL"
                      onChange={onHandleChange(renderProps, 0)}
                      value="FULL"
                      checked={isChecked(renderProps, 0, 'FULL')}
                    >
                      Full board
                    </Checkbox>
                    <Checkbox
                      className={styles['group__item']}
                      id="ALL"
                      onChange={onHandleChange(renderProps, 0)}
                      value="ALL"
                      checked={isChecked(renderProps, 0, 'ALL')}
                    >
                      All inclusive
                    </Checkbox>
                    <Checkbox
                      className={styles['group__item']}
                      id="NONE"
                      onChange={onHandleChange(renderProps, 0)}
                      value="NONE"
                      checked={isChecked(renderProps, 0, 'NONE')}
                    >
                      No meal
                    </Checkbox>
                  </div>
                );
              }}
            </FieldArray>
            <div className={styles.group} style={{ width: '100%' }}>
              <div className={styles['group__title']}>Category</div>
              <RadioGroup />
            </div>
            <div className={styles.group}>
              <div className={styles['group__title']}>Cancellation Fees</div>
              <Field name={`filter[7].values`}>
                {({
                  field: { name, value },
                  form: { setFieldValue },
                  form,
                }) => {
                  return (
                    <Checkbox
                      className={styles['group__item']}
                      value="cancellationPolicy"
                      id="cancellationPolicy"
                      onChange={onHandleBooleanChange(
                        setFieldValue,
                        name,
                        value,
                        form
                      )}
                      checked={value.includes(true)}
                    >
                      Free Cancellation
                    </Checkbox>
                  );
                }}
              </Field>
            </div>
            <FieldArray
              name={`filter[1].values`}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {renderProps => (
                <div className={styles.group}>
                  <div className={styles['group__title']}>Customer Reviews</div>
                  <Checkbox
                    className={styles['group__item']}
                    id="5"
                    onChange={onHandleChange(renderProps, 1)}
                    value="5"
                    checked={isChecked(renderProps, 1, '5')}
                  >
                    <ReviewsItems amount={5} />
                  </Checkbox>
                  <Checkbox
                    className={styles['group__item']}
                    id="4"
                    onChange={onHandleChange(renderProps, 1)}
                    value="4"
                    checked={isChecked(renderProps, 1, '4')}
                  >
                    <ReviewsItems amount={4} />
                  </Checkbox>
                  <Checkbox
                    className={styles['group__item']}
                    id="3"
                    onChange={onHandleChange(renderProps, 1)}
                    value="3"
                    checked={isChecked(renderProps, 1, '3')}
                  >
                    <ReviewsItems amount={3} />
                  </Checkbox>
                  <Checkbox
                    className={styles['group__item']}
                    id="2"
                    onChange={onHandleChange(renderProps, 1)}
                    value="2"
                    checked={isChecked(renderProps, 1, '2')}
                  >
                    <ReviewsItems amount={2} />
                  </Checkbox>
                </div>
              )}
            </FieldArray>
            <div className={styles.group}>
              <div className={styles['group__title']}>Amenities</div>
              <Field name={`filter[2].values`}>
                {({
                  field: { name, value },
                  form: { setFieldValue },
                  form,
                }) => {
                  return (
                    <Checkbox
                      className={styles['group__item']}
                      value="hasParking"
                      id="hasParking"
                      onChange={onHandleBooleanChange(
                        setFieldValue,
                        name,
                        value,
                        form
                      )}
                      checked={value.includes(true)}
                    >
                      Parking
                    </Checkbox>
                  );
                }}
              </Field>
              <Field name={`filter[3].values`}>
                {({
                  field: { name, value },
                  form: { setFieldValue },
                  form,
                }) => {
                  return (
                    <Checkbox
                      className={styles['group__item']}
                      value="hasPool"
                      id="hasPool"
                      onChange={onHandleBooleanChange(
                        setFieldValue,
                        name,
                        value,
                        form
                      )}
                      checked={value.includes(true)}
                    >
                      Swimming Pool
                    </Checkbox>
                  );
                }}
              </Field>
              <Field name={`filter[4].values`}>
                {({
                  field: { name, value },
                  form: { setFieldValue },
                  form,
                }) => {
                  return (
                    <Checkbox
                      className={styles['group__item']}
                      value="hasGym"
                      id="hasGym"
                      onChange={onHandleBooleanChange(
                        setFieldValue,
                        name,
                        value,
                        form
                      )}
                      checked={value.includes(true)}
                    >
                      Gym
                    </Checkbox>
                  );
                }}
              </Field>
            </div>
          </div>
        );
      }}
    />
  );
}
