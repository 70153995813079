import React from 'react';
import { LoginHeader } from '../LoginHeader';
import { SignInForm } from './components/SignInForm';
import { LoginFooter } from '../LoginFooter';
import { useAuth } from '../../../shared/auth';

const FOLLOW_TYPE = 'signUp';

export function SignIn({ onDone, setContentType }) {
  const { signin } = useAuth();
  return (
    <div>
      <LoginHeader title="Sign In" subtitle="Sign in to continue using our services easily" onClose={onDone}/>
      <SignInForm
        onSubmit={(values) => {
          signin(values).then(onDone).catch();
        }}
        setContentType={setContentType}/>
      <LoginFooter
        footerText="Don't have any account yet?"
        footerLink="Sign up"
        onChangeType={() => setContentType({contentType: FOLLOW_TYPE})}
      />
    </div>
  );
}
