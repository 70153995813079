import React, { memo, useCallback, useRef, useState } from 'react';

import carRent from './img/car-hire.svg';
import cruises from './img/cruises.svg';
import flights from './img/flights.svg';
import tours from './img/tours.svg';

import { useClickOutside } from '../../../shared/hooks/useClickOutside';
import {
  selectGlobalSettings,
  updateLinks,
} from '../../modules';
import { connect } from 'react-redux';
import { debounce } from '../../../shared/utils/debounce';

const typeToIcon = {
  carRent,
  cruises,
  flights,
  tours,
}

const typeToTitle = {
  carRent: 'Car hire',
  cruises: 'Cruises',
  flights: 'Flights',
  tours: 'Tours',
}

function IframeInput({ type, links, updateLinks }) {
  const [value, setValue] = useState(links?.[type] || null);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const updateLinksDebounced = debounce(nextValue => {
    updateLinks(nextValue);
  }, 300);

  const onContainerClick = useCallback(() => {
    setIsFocused(true);
    inputRef.current.focus();
  }, []);

  useClickOutside(containerRef, () => {
    setIsFocused(false);
    if (value !== links?.[type]) {
      const newLinks = { ...links, [type]: value }
      updateLinksDebounced(newLinks);
    }
  });

  const handleChange = useCallback(
    event => {
      const newValue = event.target.value;
      setValue(newValue);
    },
    [setValue]
  );

  return (
    <div
      style={{ display: 'inline-block', marginBottom: '10px', marginRight: '10px' }}
      onClick={onContainerClick}
      ref={containerRef}
    >
      <p
        style={{
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#fafafa',
          opacity: 0.5,
          marginBottom: '10px',
        }}
      >
        {typeToTitle[type]}
      </p>
      <div
        style={{
          background: '#938884',
          borderRadius: '5px',
          height: '50px',
          padding: '12px 20px',
          boxSizing: 'border-box',
          width: '170px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img src={typeToIcon[type]} />
        <input
          type="text"
          ref={inputRef}
          style={{
            padding: '0 0 0 8px',
            width: '100%',
            background: '#938884',
            border: 'none',
            outline: 'none',
            color: '#fafafa',
          }}
          value={isFocused || value?.length < 14 ? value : `${value?.slice(0, 14)}...`}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

const Enhanced = memo(
  connect(
    state => ({
      links: selectGlobalSettings(state),
    }),
    { updateLinks }
  )(IframeInput)
);

export { Enhanced as IframeInput };
