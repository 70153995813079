import React from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

export function Button(props) {
  const { type, text, shadowed, onClick } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      className={cn(styles.button, {
        [styles['button--shadowed']]: shadowed,
      })}
    >
      {text}
    </button>
  );
}
