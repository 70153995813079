import { combineReducers } from 'redux';

import hotels from '../../hotels/modules';
import login from '../../login/modules';
import admin from '../../admin/modules';

export default combineReducers({
    hotels,
    login,
    admin,
});
