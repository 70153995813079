import { createAction } from "redux-actions";
import { client } from "../../shared/api";

export const setReservations = createAction("ADMIN::SET_RESERVATIONS");
export const setIsLoading = createAction("ADMIN::SET_IS_LOADING");
export const setCoefficient = createAction("ADMIN::SET_COEFFICIENT");
export const setGlobalSettings = createAction("ADMIN::SET_GLOBAL_SETTINGS");
export const setPaginationInfo = createAction("ADMIN::SET_PAGINATION_INFO");

export const getBookings = (page = 0, date) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      let url = `booking?page=${page}&size=10&sort=ASC`;
      if (date?.checkIn && date?.checkOut) {
        url = `${url}&from=${date.checkIn}&to=${date.checkOut}`;
      }
      const {
        hotels: bookings,
        page: curPage,
        totalElements,
        totalPages,
      } = await client(url);
      dispatch(setReservations(bookings));
      dispatch(setPaginationInfo({ page: curPage, totalElements, totalPages }));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const cancel = (reservation) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await client("booking/cancel", {
        body: {
          email: reservation.email,
          bookingId: reservation.bookingId,
          source: reservation.source || "HB",
        },
        method: "DELETE",
      });
      dispatch(getBookings());
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateCoefficient = (priceCoefficient) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await client("settings", {
        body: {
          priceCoefficient,
        },
      });
      dispatch(setCoefficient(priceCoefficient));
      dispatch(getBookings());
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const getGlobalSettings = () => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const data = await client("settings", { method: "GET" });
      dispatch(setCoefficient(data.priceCoefficient));
      dispatch(setGlobalSettings(data));
    } catch {
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const updateLinks = (links) => {
  return async () => {
    try {
      await client("settings/links", { body: links });
    } catch (e) {
      console.log(e);
    }
  };
};
