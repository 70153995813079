import { connect } from 'react-redux';
import { compose } from 'redux';
import { setContentType, register } from '../../modules';
import { SignUp } from './SignUp';

const mapDispatchToProps = {
  setContentType,
  register,
}

const mapStateToProps = (state) => ({
  status: state.login.status,
  message: state.login.message,
})

const Enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(SignUp);

export { Enhanced as SignUp };
