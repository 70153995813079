import { connect } from 'react-redux';

import { HotelsFilter } from './HotelsFilter';
import { loadHotels } from '../../modules';

const mapDispatchToProps = dispatch => ({
  loadHotels: values => dispatch(loadHotels(values)),
});

const Enhanced = connect(null, mapDispatchToProps)(HotelsFilter);

export { Enhanced as HotelsFilter };
