import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import style from './Reserve.module.scss';
import icon from './img/close-icon.png';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Input } from '../../../shared/components/Input';
import { useAuth } from '../../../shared/auth';
import moment from 'moment';

const styles = {
  content: {
    position: 'absolute',
    top: '100px',
    left: 0,
    bottom: 0,
    right: 0,
    width: '513px',
    height: '657px',
    margin: 'auto',
    padding: '40px 40px',
    background: '#FAFAFA',
    border: 'none',
    'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.25)',
    'border-radius': '5px',
  }
};

const format = 'DD-MM-YYYY';

export function Reserve({hotel, filters, room, closeModal, modalIsOpen, onSubmit}) {
  const [initialValues, setInitialValues] = useState(null);
  const {user} = useAuth();
  const {firstName, lastName, email, phone} = user || {};

  useEffect(() => {
    const creator = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phone: phone || '',
    };
    const newInitialValues = {
      creator,
      persons: [],
    };
    const defaultValue = {
      firstName: '',
      lastName: '',
    };
    filters.occupancies && filters.occupancies.forEach(({adults, children}) => {
      for (let i = 0; i < adults; i++) {
        newInitialValues.persons.push({...defaultValue});
      }

      for (let i = 0; i < children?.length; i++) {
        newInitialValues.persons.push({...defaultValue, childAge: children[i]});
      }

      setInitialValues(newInitialValues);
    });
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={styles}
    >
      <div className={style.content}>
        <div className={style.header}>
          <h1>Confirm your reservation</h1>
          <img className={style['close-icon']} onClick={closeModal} src={icon} alt="close"/>
        </div>
        <div className={style.info}>
          <div className={style.thumb}>
            <img src={hotel.thumbnail}/>
          </div>
          <div>
            <figcaption>Destination</figcaption>
            <div className={style.dest}>{hotel.destName}</div>
            <figcaption>Hotel</figcaption>
            <div className={style.hotel}>{`${hotel.name}, ${room.name}`}</div>
          </div>
        </div>
        <div className={style.filters}>
          <div>
            <figcaption>Dates</figcaption>
            {`${moment(filters.date.checkIn).format(format)}/${moment(filters.date.checkOut).format(format)}`}
          </div>
          <div className={style.rooms}>
            <div>
              <figcaption>Rooms</figcaption>
              {filters.occupancies.length}
            </div>
            <div>
              <figcaption>Adults</figcaption>
              {filters.occupancies.reduce((res, occ) => {
                return res + occ.adults;
              }, 0)}
            </div>
            <div>
              <figcaption>Children</figcaption>
              {filters.occupancies.reduce((res, occ) => {
                return res + (occ.children?.length || 0);
              }, 0)}
            </div>
          </div>
        </div>
        <div>
          {initialValues && <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({values}) => (
              <Form>
                <div className={style.row}>
                  <Field
                    className={style.input}
                    type="firstName"
                    name={`creator.firstName`}
                    figcaption="First Name (booking holder)"
                    component={Input}
                  />
                  <Field
                    className={style.input}
                    type="lastName"
                    name={`creator.lastName`}
                    figcaption="Last Name (booking holder)"
                    component={Input}
                  />
                </div>
                <div className={style.row}>
                  <Field
                    className={style.input}
                    type="email"
                    name={`creator.email`}
                    figcaption="E-mail"
                    component={Input}
                  />
                  <Field
                    className={style.input}
                    type="phone"
                    name={`creator.phone`}
                    figcaption="Phone (optional)"
                    component={Input}
                  />
                </div>
                <FieldArray
                  name="persons"
                  render={() => {
                    return (
                      <div>
                        {values.persons.map((person, i) =>
                          <div className={style.row}>
                            <Field
                              className={style.input}
                              type="firstName"
                              name={`persons[${i}].firstName`}
                              figcaption={`First Name (guest ${i + 1})`}
                              component={Input}
                            />
                            <Field
                              className={style.input}
                              type="lastName"
                              name={`persons[${i}].lastName`}
                              figcaption={`Last Name (guest ${i + 1})`}
                              component={Input}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }}
                >
                </FieldArray>
                <div className={style.footer}>
                  <button className={style.button} type="submit">Confirm</button>
                  <div><span>Total</span> <span className={style.net}>{room.net} EUR</span></div>
                </div>
              </Form>
            )}
          </Formik>}
        </div>
      </div>
    </Modal>
  );
}
