import { createAction } from 'redux-actions';
import { client } from '../../shared/api';

export const setContentType = createAction('LOGIN::SET_CONTENT_TYPE');
export const setStatus = createAction('LOGIN::SET_STATUS');
export const setMessage = createAction('LOGIN::SET_MESSAGE');
export const setIsVerified = createAction('VERIFICATION::SET_IS_VERIFIED');
export const setVerificationInProgress = createAction('VERIFICATION::SET_VERIFICATION_IN_PROGRESS');

export const verify = token => {
  return async dispatch => {
    dispatch(setVerificationInProgress(true));
    try {
      await client(`registration/verify/${token}`)
      dispatch(setIsVerified(true));
    } catch (e) {
      dispatch(setIsVerified(false));
    }
    dispatch(setVerificationInProgress(false));
  };
};

export const register = userInfo => {
  return async dispatch => {
    try {
      await client('registration', { body: userInfo });
      dispatch(setStatus('success'));
      dispatch(setMessage('Success. Please, check your email address!'))
    } catch (e) {
      console.error(e);
      dispatch(setStatus('failed'));
      dispatch(setMessage('Oops, something went wrong. Please, try again later!'))
    }
  }
}

export const updateDetails = userDetails => {
  return async dispatch => {
    try {
     await client('user/update', { method: 'PUT', body: userDetails })
    } catch (e) {
      console.error(e);
    }
  }
}
