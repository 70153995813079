import React from 'react';

import { HeaderNavigation } from '../../shared/components/HeaderNavigation';
import { MainNavigation } from '../../shared/components/MainHavigation';
import { Loader } from '../../shared/components';

import './Verification.scss';
import { Redirect } from 'react-router';

function getQueryToken(search) {
  const [_, token] = search.replace('?', '').split('=');
  return token;
}

export function Verification({ location, isVerified, verificationInProgress, verify }) {
  const { search } = location;
  const token = getQueryToken(search);

  if (!token) {
    return (<Redirect to={{ pathname: '/auth' }} />)
  }

  verify(token);
  return (
    <div className="header">
      <HeaderNavigation/>
      { isVerified || !verificationInProgress ? <Redirect to={{ pathname: '/auth' }} /> : <Loader /> }
      <MainNavigation/>
    </div>
  );
}
