import React from 'react';
import cn from 'classnames';

import styles from './ReviewsItems.module.scss';

export function ReviewsItems(props) {
    const renderButtons = (amount) => {
        const buttons = [];
        for (let i = 0; i < 5; i++) {
            buttons.push(<div className={cn(styles.button, {
                [styles['button--filled']]: i < amount
            })} />)
        }
        return buttons;
    }

    return (
        <div className={styles.wrapper}>
            {renderButtons(props.amount)}
        </div>
    )
}
