import React from 'react';
import styles from './LoginFooter.module.scss';
import gmailIcon from './img/gmail-icon.png';
import linkedInIcon from './img/linkedIn-icon.png';
import alignableIcon from './img/alignable-icon.png';

export function LoginFooter({ footerText, footerLink, onChangeType, showDivider }) {
  return (
    <div>
      { showDivider && <div className={styles.divider}>or</div> }
      <div className={styles.social}>
        <button>
          <div><img src={gmailIcon} alt="gmail" /></div>
          <span>Gmail</span>
        </button>
        <button>
          <div><img src={linkedInIcon} alt="linkedIn" /></div>
          <span>LinkedIn</span>
        </button>
        <button>
          <div><img src={alignableIcon} alt="alignable" /></div>
          <span>Alignable</span>
        </button>
      </div>
      <div>
        <span>{footerText}</span>
        <a className={styles.link} onClick={onChangeType}>{footerLink}</a>
      </div>
    </div>
  )
}
