import { connect } from 'react-redux';
import { compose } from 'redux';
import { loadHotels, selectIsLoading } from '../../hotels/modules';
import { Hotels } from './Hotels';
import { withLoader } from '../../shared/HOC';

const mapDispatchToProps = {
  loadHotels,
};

const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
});

const Enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLoader
)(Hotels);

export { Enhanced as Hotels };
