import React, { useCallback, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import cn from 'classnames';

import styles from './HeaderNavigation.module.scss';

import profile from './img/profile-icon.svg';
import logo from './img/logo.png';
import { useAuth } from '../../auth';

const links = [
  {href: '#', title: 'Transfers and Excursions'},
  {href: '#', title: 'Travel insurance'},
  {href: '#', title: 'Private flights'},
  {href: '#', title: 'Education'},
  {href: '/about-us', title: 'About us'},
  {href: '/contacts', title: 'Contacts'},
];

export function HeaderNavigation(props) {
  const [showMenu, setShowMenu] = useState(false);
  const auth = useAuth();
  useEffect(() => {
    const closeMenu = (e) => {
      if (e.target.nodeName !== 'LI') {
        setShowMenu(false)
      }
    };
    document.body.addEventListener('click', closeMenu);

    return () => document.body.removeEventListener('click', closeMenu);
  }, []);

  const toggleMenu = useCallback(() => setShowMenu(state => !state), [setShowMenu]);

  return (
    <div className={styles.header__container}>
      <div>
        {props.withLogo && (
          <Link to="/">
            <img className={styles.header__logo} src={logo} alt="logo"/>
          </Link>
        )}
      </div>
      <ul
        className={cn(styles.header__menu, {
          [styles['header__menu--centered']]: !props.withLogo,
        })}
      >
        {links.map(link => (
          <li className={styles.header__item} key={link.title}>
            <Link to={link.href}>{link.title}</Link>
          </li>
        ))}
      </ul>
      <div>
        <img
          src={profile}
          onClick={toggleMenu}
        />
        <ul className={cn(styles.header__user__menu, {
          [styles.hidden]: !showMenu
        })}>
            {auth.user
              ? <>
                <li>
                  <Link to="/bookings">{auth.isAdmin ? 'Admin Panel' : 'Bookings'}</Link>
                </li>
                <li>
                  <Link to="/settings">Settings</Link>
                </li>
                <li onClick={() => auth.signout()}>Log Out
                </li>
              </>
              : <li><Link to="/auth">Log In</Link></li>}
          </ul>
      </div>
    </div>
  );
}
