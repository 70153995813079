import React from 'react';
import Carousel from 'react-images';

// const images = [
//   { source: 'path/to/image-1.jpg' },
//   { source: 'path/to/image-2.jpg' },
// ];

export function HotelCardImages(props) {
  return (
    <Carousel
      views={props.images}
      hideControlsWhenIdle={false}
      components={{
        Footer: null,
      }}
      styles={{
        view: base => ({
          ...base,
        }),
        navigationPrev: base => ({
          ...base,
          width: '40px',
          left: 0,
          bottom: 0,
          top: '25px',
          height: '40px',
          color: '#000',
          outline: 'none',
        }),
        navigationNext: base => ({
          ...base,
          width: '40px',
          right: 0,
          bottom: 0,
          top: '25px',
          height: '40px',
          color: '#000',
          outline: 'none',
        }),
        navigation: base => ({
          ...base,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          position: 'absolute',
          width: '80px',
          height: '40px',
          right: '16px',
          bottom: '16px',
          borderRadius: '5px',
          backgroundColor: '#fff',
        }),
      }}
    />
  );
}
