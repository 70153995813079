import React from 'react';
import { HeaderNavigation } from '../../shared/components';
import { MainNavigation } from '../../shared/components/MainHavigation';
import styles from './Contacts.module.scss';
import destIcon from './img/destination_icon.svg';
import emailIcon from './img/email_icon.svg';
import phoneIcon from './img/phone_icon.svg';

export function Contacts() {
  return (
    <div>
      <HeaderNavigation withLogo />
      <div className={styles.contacts}>
        <div className={styles.block}>
          <h1>Contacts</h1>
          <div>
            <h3>Canada</h3>
            <div className={styles.content}>
              {/*<p><img src={destIcon} /> Northforce Co., LTD</p>*/}
              <p><img src={phoneIcon} />+1 780 800 7412</p>
              <p><img src={emailIcon} />info@northforce.ca</p>
            </div>
          </div>
            <div>
                <h3>USA</h3>
                <div className={styles.content}>
                    {/*<p><img src={destIcon} /> Northforce Co., LTD</p>*/}
                    <p><img src={phoneIcon} />+1 510 800 7412</p>
                    <p><img src={emailIcon} />info@northforce.ca</p>
                </div>
            </div>
            <div>
                <h3>Switzerland</h3>
                <div className={styles.content}>
                    {/*<p><img src={destIcon} /> Northforce Co., LTD</p>*/}
                    <p><img src={phoneIcon} />+41 792 372 538</p>
                    <p><img src={emailIcon} />info@northforce.ca</p>
                </div>
            </div>
            <div>
                <h3>Spain</h3>
                <div className={styles.content}>
                    {/*<p><img src={destIcon} /> Northforce Co., LTD</p>*/}
                    <p><img src={phoneIcon} />+34 624 022 943</p>
                    <p><img src={emailIcon} />info@northforce.ca</p>
                </div>
            </div>
          {/*<div className={styles['info-block']}>*/}
            {/*<div>*/}
            {/*  <h3>Ukraine</h3>*/}
            {/*  <div className={styles.content}>*/}
            {/*    <p>*/}
            {/*      <img src={destIcon} />*/}
            {/*      Art Shop of Travels "Creation"*/}
            {/*    </p>*/}
            {/*    <p>*/}
            {/*      Anri Barbyusa street 58/1, Premises 28A,*/}
            {/*    </p>*/}
            {/*    <p>*/}
            {/*      Kyiv, 03150, Ukraine*/}
            {/*    </p>*/}
            {/*    <p>*/}
            {/*      <img src={phoneIcon} />*/}
            {/*      +38 050 385 3072*/}
            {/*    </p>*/}
            {/*    <p><img src={emailIcon} />office@astcreation.com</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          {/*</div>*/}
        </div>

        {/*<div className={styles.block}>*/}
        {/*  <h1>Partners</h1>*/}
        {/*  <div className={styles['info-block']}>*/}
        {/*    <div>*/}
        {/*      <h3>Canada</h3>*/}
        {/*      <div className={styles.content}>*/}
        {/*        <p><img src={destIcon} /> Northforce Co., LTD</p>*/}
        {/*        <p><img src={phoneIcon} />+1 780 800 7412</p>*/}
        {/*        <p><img src={emailIcon} />info@northforce.ca</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <h3>USA</h3>*/}
        {/*      <div className={styles.content}>*/}
        {/*        <p><img src={destIcon} /> Northforce LLC,</p>*/}
        {/*        <p><img src={phoneIcon} />+1 916 333 0655</p>*/}
        {/*        <p><img src={emailIcon} />v.ruslan@northforce.ca</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <MainNavigation left />
    </div>
  )
}
