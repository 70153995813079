import React, { useEffect, useState } from "react";
import moment from "moment";
import { Form, Formik } from "formik";
import { Route, useHistory, useLocation } from "react-router";

import { TopHotels } from "../../hotels/components/TopHotels";
import { HotelsFilter } from "../../hotels/components/Filters";
import { HotelsSearch } from "../../hotels/components/HotelsSearch";
import { HeaderNavigation } from "../../shared/components/HeaderNavigation";
import { MainNavigation } from "../../shared/components/MainHavigation";
import { DATE_TIME_FORMAT } from "../../shared/constants/datetime";

import styles from "./Hotels.module.scss";

export const EMPTY_FILTER = [
  {
    field: "board",
    values: [],
  },
  {
    field: "review",
    values: [],
  },
  {
    field: "hasParking",
    values: [],
  },
  {
    field: "hasPool",
    values: [],
  },
  {
    field: "hasGym",
    values: [],
  },
  {
    field: "category",
    values: [],
  },
  {
    field: "location",
    values: [],
  },
  {
    field: "cancellationPolicy",
    values: [],
  },
  {
    field: "hotelName",
    values: [],
  },
];

const INITIAL_FILTERS = {
  destination: null,
  date: {
    checkIn: moment().format(DATE_TIME_FORMAT),
    checkOut: moment().add(3, "days").format(DATE_TIME_FORMAT),
  },
  occupancies: [
    {
      adults: 2,
    },
  ],
  filter: EMPTY_FILTER,
  page: 0,
  sort: "ASC",
  size: 10,
};

function valuesToQuery(values) {
  return Object.keys(values).reduce((str, key) => {
    return str + `&${key}=${encodeURIComponent(JSON.stringify(values[key]))}`;
  }, "");
}

export function Hotels(props) {
  const history = useHistory();
  const { search } = useLocation();
  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    if (search && search !== "?search") {
      const query = new URLSearchParams(search);
      const newInitialValues = {};
      for (const [key, value] of query.entries()) {
        if (key !== search) {
          try {
            newInitialValues[key] = JSON.parse(decodeURIComponent(value));
          } catch {
            newInitialValues[key] = value;
          }
        }
      }
      setInitialValues(newInitialValues);
      props.loadHotels(newInitialValues);
    } else {
      setInitialValues(INITIAL_FILTERS);
    }
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <HeaderNavigation withLogo />
      <div className={styles.wrapper}>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              await props.loadHotels(values);
              return history.push(`/hotels?search${valuesToQuery(values)}`, {});
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {() => {
              return (
                <Form className={styles.form}>
                  <HotelsFilter />

                  <Route
                    path="/hotels"
                    render={(renderProps) => {
                      return renderProps.location.search.includes("search") ? (
                        <HotelsSearch />
                      ) : (
                        <TopHotels className={styles["top-hotels"]} />
                      );
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      <MainNavigation left />
    </div>
  );
}
