import React from 'react';
import AsyncSelect from 'react-select/async';

export function Select(props) {
  return (
    <div style={props.style}>
      <figcaption>{props.figcaption}</figcaption>
      <AsyncSelect
        inputValue={props.label}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        cacheOptions
        defaultOptions
        onMenuClose={props.onMenuClose}
        value={props.value}
        classNamePrefix={props.classNamePrefix}
        loadOptions={props.loadOptions}
        styles={props.selectStyles}
        placeholder={props.placeholder}
        components={props.components}
      />
    </div>
  );
}
