import React, { useContext, createContext, useState, useEffect } from 'react';
import { client } from '../api';
import { useHistory } from 'react-router';
import axios from 'axios';

const authContext = createContext(null);


export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const history = useHistory();
  useEffect(() => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        window.localStorage.removeItem('user');
        history.push('/auth');
        setUser(null);

        return Promise.reject(error);
      }
    )

    try {
      if (!user) {
        setUser(JSON.parse(window.localStorage.getItem('user')));
      }
    } catch (e) {
      setUser(null);
    }
  }, [])

  const signin = async ({ email, password }) => {
    const form = new FormData();
    form.append('username', email);
    form.append('password', password)
    try {
      const resp = await client('login', { body: form, withCredentials: true }) || { admin: false };
      setUser(resp);
      window.localStorage.setItem('user', JSON.stringify(resp));
      history.push('/');
    } catch (e) {
    }
  };

  const signout = () => {
    setUser(null);
    window.localStorage.removeItem('user');
    history.push('/auth');
  };

  return {
    user,
    isAdmin: user?.admin,
    signin,
    signout
  };
}

