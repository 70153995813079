import { connect } from 'react-redux';
import { compose } from 'redux';
import { setContentType } from '../../modules';
import { SignIn } from './SignIn';

const mapDispatchToProps = {
  setContentType,
}

const Enhanced = compose(
  connect(null, mapDispatchToProps),
)(SignIn);

export { Enhanced as SignIn };
