import React from 'react';
import { HeaderNavigation } from '../../shared/components';
import { MainNavigation } from '../../shared/components/MainHavigation';
import styles from './AboutUs.module.scss';

export function AboutUs() {
    return (
        <div>
            <HeaderNavigation withLogo />
            <div className={styles.contacts}>
                <h1>About Us</h1>
                <div className={styles.block}>
                    <p>Introducing you to Travel Art Shop, the division of Northforce Co Ltd. Since this company open its doors, its been working for chosen only. For those who appreciates its time at work and pleasure. Simply we are beautiful minded tour operator company, but there's one thing beyond any doubt: that our company doing best: we are helping you to find common bonds and words to build bridges between multilingual coasts - for the sake of international unity and understanding your vocation or business trip! (READ MORE…)</p>
                    <p>When you do not speak the same language of the country you are planning to go, we are coming to help also. Neither cultural, nor economic, nor political connections between different peoples and countries are possible without knowledge of languages. That is the spot we are coming to play our role.</p>
                    <p>Our Company creates quality tour vocation and/or business trips, demonstrative and informative plan of your trip, so that you could reach understanding whenever you go, work out the optimal route of action and navigate through it.</p>
                    <p>Our company was founded in 2010 and we were created by people who already had a decade of experience in the field of travel throughout the world. It was originally a brotherhood of true pros who love this kind of work.</p>
                    <p>Over the years since the establishment of the company we tempered and strengthened the original spirit. By opening the company in post crises period, we are not only held our position in the industry, but also expanded the range of specialized tours and its quality. The 2011 was a year of recognition. Major and reputable companies and individuals, started working with us.</p>
                    <p>The violent crisis made us stronger. We been in demand and useful in spite of all the difficulties faced by the tour industry. Nowadays among us there are a lot of tour companies who are competent in subject areas and able to provide the service for which you have request for, and probably meet most of your wishes and suggest the optimal solution.</p>
                    <p>In many ways we can be better!</p>
                    <p>1) We are extremely attentive to our customers.</p>
                    <p>2) Our managers have more than 10 years of worldwide experience.</p>
                    <p>3) No need of editing and proof-reading by you. We guarantee relevancy of your wish.</p>
                    <p>4) Good value for money for every trip of yours.</p>
                    <p>5) Individual approach to your needs.</p>
                    <p>6) Faithful fulfilment of commitments.</p>
                    <p>7) Direct excess to private aircraft jets and VIP level auto park.</p>
                    <p>Travel Art Shop (TAS) is a science of how to choose your personal way and methods of the movement of vessels, aircraft those be used by you.</p>
                    <p>Finding the optimal route for you is our task.</p>
                </div>
            </div>
            <MainNavigation left />
        </div>
    );
}






