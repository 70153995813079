import { handleActions } from 'redux-actions';
import {
  setContentType,
  setIsVerified,
  setMessage,
  setStatus,
  setVerificationInProgress
} from './login.actions';
import { CONTENT_TYPE } from '../constants';

export default handleActions(
  {
    [setContentType]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [setIsVerified]: (state, { payload }) => {
      return {
        ...state,
        isVerified: payload,
      }
    },
    [setVerificationInProgress]: (state, { payload }) => {
      return {
        ...state,
        verificationInProgress: payload,
      }
    },
    [setStatus]: (state, { payload }) => {
      return {
        ...state,
        status: payload,
      }
    },
    [setMessage]: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      }
    },
  },
  {
    contentType: CONTENT_TYPE.signIn,
    isVerified: false,
    verificationInProgress: true,
    status: null,
    message: null,
  }
);
