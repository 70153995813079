import React, { useEffect, useRef } from 'react';

/*global google*/

export function PlacesAutocomplete(props) {
  const inputRef = useRef();
  useEffect(() => {
    const autocomplete = new google.maps.places.Autocomplete(inputRef.current);
    autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    const listener = () => {
      const place = autocomplete.getPlace();
      if (place) {
        props.onChange &&
          props.onChange([
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ]);
      }
    };
    autocomplete.addListener('place_changed', listener);
  }, [inputRef]);

  return (
    <>
      <input style={props.style} ref={inputRef} />
    </>
  );
}
