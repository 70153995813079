import React, { useMemo } from 'react';
import { GoogleMap, Marker } from 'react-google-maps';

export function HotelCardMap({ lat, lng }) {
  const latLongConfig = useMemo(
    () => ({
      lat,
      lng,
    }),
    [lat, lng]
  );
  return (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={latLongConfig}
      clickableIcons={false}
    >
      <Marker position={latLongConfig} />
    </GoogleMap>
  );
}
