import React, { useMemo } from 'react';
import moment from 'moment';
import { Field, useField } from 'formik';
import {
  DropDown,
  DatePicker,
  Delimiter,
  Counter,
  Button,
} from '../../../shared/components';
import { HotelsGroupSelect } from '../HotelsGroupSelect';

import styles from './HotelsFilter.module.scss';

import night from './img/nights_icon.svg';
import rooms from './img/rooms-icon.svg';
import { DATE_TIME_FORMAT } from '../../../shared/constants/datetime';

export function HotelsFilter() {
  const [date] = useField('date');
  const nights = useMemo(() => {
    if (!date.value.checkOut || !date.value.checkIn) return '';
    return moment(date.value.checkOut).diff(moment(date.value.checkIn), 'days');
  }, [date.value]);

  return (
    <div className={styles.filters}>
      <Field name="destination" component={HotelsGroupSelect} />
      <Field name="date" component={DatePicker} />
      <Field name="shiftDays">
        {({ _, form }) => {
          return (
            <div>
              <figcaption>Nights</figcaption>
              <DropDown
                size="l"
                components={{
                  Input: ({ onClick }) => (
                    <div className={styles.input} onClick={onClick}>
                      <img src={night} alt="icon night" />
                      <span className={styles['input-wrapper']}>{nights}</span>
                    </div>
                  ),
                  Menu: () => (
                    <section className={styles.menu}>
                      <span style={{ margin: '0 20px' }}>Nights</span>
                      <Counter
                        value={nights}
                        onIncreaseClick={() =>
                          form.setFieldValue('date', {
                            ...form.values.date,
                            checkOut: moment(form.values.date.checkOut)
                              .add(1, 'days')
                              .format(DATE_TIME_FORMAT),
                          })
                        }
                        onDecreaseClick={() => {
                          if (nights > 1) {
                            form.setFieldValue('date', {
                              ...form.values.date,
                              checkOut: moment(form.values.date.checkOut)
                                .subtract(1, 'days')
                                .format(DATE_TIME_FORMAT),
                            });
                          }
                        }}
                      />
                    </section>
                  ),
                }}
              />
            </div>
          );
        }}
      </Field>
      <Field name="occupancies">
        {({ field, form, meta }) => {
          return (
            <div>
              <figcaption>Rooms</figcaption>
              <DropDown
                size="l"
                right
                components={{
                  Input: ({ onClick }) => (
                    <div className={styles.input} onClick={onClick}>
                      <img
                        src={rooms}
                        alt="icon rooms"
                        style={{ marginRight: '16px' }}
                      />
                      <span
                        className={styles['rooms-wrapper']}
                        style={{
                          width: '24px',
                          textAlign: 'left',
                        }}
                      >
                        {field.value.length}
                      </span>
                      <Delimiter />
                      <span
                        className={styles['rooms-wrapper']}
                        style={{ width: '44px', textAlign: 'center' }}
                      >
                        {field.value.reduce(
                          (acc, entry) => acc + entry.adults,
                          0
                        )}
                      </span>
                      <Delimiter />
                      <span
                        className={styles['rooms-wrapper']}
                        style={{ width: '24px', textAlign: 'right' }}
                      >
                        {field.value.reduce(
                          (acc, entry) => acc + (entry.children?.length ?? 0),
                          0
                        )}
                      </span>
                    </div>
                  ),
                  Menu: () => (
                    <div className={styles['dropdown-wrapper']}>
                      <div
                        className={styles.dropdown}
                        style={{ marginRight: '20px' }}
                      >
                        <span style={{ marginRight: '16px' }}>Rooms</span>
                        <Counter
                          value={field.value.length}
                          onIncreaseClick={() => {
                            if (field.value.length === 4) return;
                            form.setFieldValue(field.name, [
                              ...field.value,
                              { adults: 1 },
                            ]);
                          }}
                          onDecreaseClick={() => {
                            if (field.value.length > 1) {
                              form.setFieldValue(
                                field.name,
                                field.value.slice(0, -1)
                              );
                            }
                          }}
                        />
                      </div>
                      <Delimiter style={{ marginTop: '8px' }} dark />
                      <div style={{ display: 'flex', flexFlow: 'column' }}>
                        {field.value.map((entry, i) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginBottom: '20px',
                              }}
                            >
                              <div
                                className={styles.dropdown}
                                style={{ margin: '0 20px' }}
                              >
                                <span style={{ marginRight: '16px' }}>
                                  Adults
                                </span>
                                <Counter
                                  value={entry.adults}
                                  onIncreaseClick={() => {
                                    const newFieldValue = [...field.value];
                                    newFieldValue[i].adults =
                                      newFieldValue[i].adults + 1;
                                    form.setFieldValue(
                                      field.name,
                                      newFieldValue
                                    );
                                  }}
                                  onDecreaseClick={() => {
                                    if (field.value[i].adults > 1) {
                                      const newFieldValue = [...field.value];
                                      newFieldValue[i].adults =
                                        newFieldValue[i].adults - 1;
                                      form.setFieldValue(
                                        field.name,
                                        newFieldValue
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <Delimiter style={{ marginTop: '8px' }} dark />
                              <div
                                className={styles.dropdown}
                                style={{
                                  marginLeft: '20px',
                                  ...(field.value[i].children?.length > 0 && {
                                    marginRight: '20px',
                                  }),
                                }}
                              >
                                <span style={{ marginRight: '16px' }}>
                                  Children
                                </span>
                                <Counter
                                  value={(entry?.children ?? []).length}
                                  onIncreaseClick={() => {
                                    const newValue = [...field.value];
                                    newValue[i].children = newValue[i].children
                                      ? [...newValue[i].children, 0]
                                      : [0];
                                    form.setFieldValue(field.name, newValue);
                                  }}
                                  onDecreaseClick={() => {
                                    const newValue = [...field.value];
                                    newValue[i].children = newValue[
                                      i
                                    ].children?.slice(0, -1);
                                    form.setFieldValue(field.name, newValue);
                                  }}
                                />
                              </div>
                              {field.value[i]?.children?.length > 0 && (
                                <>
                                  <Delimiter
                                    style={{ marginTop: '8px' }}
                                    dark
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexFlow: 'column',
                                    }}
                                  >
                                    {field.value[i]?.children.map(
                                      (child, childIndex) => (
                                        <div
                                          className={styles.dropdown}
                                          style={{
                                            marginLeft: '20px',
                                            marginBottom: '20px',
                                          }}
                                        >
                                          <span style={{ marginRight: '16px' }}>
                                            Age
                                          </span>
                                          <Counter
                                            value={entry.children[childIndex]}
                                            onIncreaseClick={() => {
                                              const newValue = [...field.value];
                                              newValue[i].children[childIndex] =
                                                newValue[i].children[
                                                  childIndex
                                                ] + 1;
                                              form.setFieldValue(
                                                field.name,
                                                newValue
                                              );
                                            }}
                                            onDecreaseClick={() => {
                                              const newValue = [...field.value];
                                              newValue[i].children[childIndex] =
                                                newValue[i].children[
                                                  childIndex
                                                ] - 1;
                                              form.setFieldValue(
                                                field.name,
                                                newValue
                                              );
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ),
                }}
              />
            </div>
          );
        }}
      </Field>
      <Button
        type="submit"
        text="Search"
        shadowed={true}
        style={{ marginTop: '16px !important' }}
      />
    </div>
  );
}
