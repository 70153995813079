import axios from 'axios';

export function client(endpoint, { body, method, ...customConfig } = {}) {
  // const token = window.localStorage.getItem(localStorageKey)
  const headers = { 'content-type': 'application/json' };
  // if (token) {
  //     headers.Authorization = `Bearer ${token}`
  // }

  const reqMethod = method || (body ? 'post' : 'get');

  const config = {
    method: reqMethod,
    withCredentials: true,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.data = body instanceof FormData ? body : JSON.stringify(body);
  }
  return axios
    .request({
      url: `/${endpoint}`,
      baseURL: 'https://app.travelartshop.ca',
      ...config,
    })
    .then(async response => {
      if (response.status === 200) {
        return response.data;
      } else {
        return Promise.reject(response.data);
      }
    })
}
