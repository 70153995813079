import { handleActions } from 'redux-actions';
import { setHotels, setIsLoading, setTopHotels } from './hotels.actions';

export default handleActions(
  {
    [setHotels]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [setTopHotels]: (state, { payload }) => {
      return {
        ...state,
        topHotels: payload,
      };
    },
    [setIsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
  },
  {
    hotels: [],
    topHotels: [],
    isLoading: false,
  }
);
