import React from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

export function Checkbox(props) {
  const { children, id, onChange, checked, className, value } = props;

  const onHandleChange = () => {
    onChange(value);
  };

  return (
    <div className={cn(className, styles['wrapper-checkbox'])}>
      <input
        type="checkbox"
        id={id}
        onChange={onHandleChange}
        checked={checked}
        value={value}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}
