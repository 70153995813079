import React from 'react';
import { FieldArray } from 'formik';
import cn from 'classnames';
import { Delimiter } from '../Delimiter';

import fiveStars from './img/5-stars_icon.svg';
import fourStars from './img/4-stars_icon.svg';
import threeStars from './img/3-stars_icon.svg';
import twoStars from './img/2-stars_icon.svg';
import ultra from './img/key-icon.svg';

import styles from './RadioGroup.module.scss';

export function RadioGroup() {
  const onHandleChange = (renderProps, index, value) => () => {
    const idx = renderProps.form.values.filter[index].values.indexOf(value);
    if (idx >= 0) {
      renderProps.remove(idx);
      return renderProps.form.handleSubmit(renderProps.form.values);
    }
    renderProps.push(value);
    return renderProps.form.handleSubmit(renderProps.form.values);
  };

  const isChecked = (renderProps, index, value) => {
    return renderProps.form.values.filter[index].values.includes(value);
  };

  // checked={isChecked(renderProps, 0, 'BREAKFAST')}

  return (
    <FieldArray
      name={`filter[5].values`}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {renderProps => {
        return (
          <div className={styles.wrapper}>
            <div
              className={cn(styles.item, {
                [styles.selected]: isChecked(renderProps, 5, 5),
              })}
              onClick={onHandleChange(renderProps, 5, 5)}
            >
              <img src={fiveStars} />
            </div>
            <Delimiter />
            <div
              className={cn(styles.item, {
                [styles.selected]: isChecked(renderProps, 5, 4),
              })}
              onClick={onHandleChange(renderProps, 5, 4)}
            >
              <img src={fourStars} />
            </div>
            <Delimiter />
            <div
              className={cn(styles.item, {
                [styles.selected]: isChecked(renderProps, 5, 3),
              })}
              onClick={onHandleChange(renderProps, 5, 3)}
            >
              <img src={threeStars} />
            </div>
            <Delimiter />
            <div
              className={cn(styles.item, {
                [styles.selected]: isChecked(renderProps, 5, 2),
              })}
              onClick={onHandleChange(renderProps, 5, 2)}
            >
              <img src={twoStars} />
            </div>
            <Delimiter />
            <div
              className={cn(styles.item, {
                [styles.selected]: isChecked(renderProps, 5, 1),
              })}
              onClick={onHandleChange(renderProps, 5, 1)}
            >
              <img src={ultra} />
            </div>
            <Delimiter />
          </div>
        );
      }}
    </FieldArray>
  );
}
