import { connect } from 'react-redux';

import { HotelsSearchFooter } from './HotelsSearchFooter';
import { selectPage, selectTotalHotels, selectTotalPages } from '../../modules';

const mapStateToProps = state => ({
  totalPages: selectTotalPages(state),
  totalHotels: selectTotalHotels(state),
  page: selectPage(state),
});

const Enhanced = connect(mapStateToProps)(HotelsSearchFooter);

export { Enhanced as HotelsSearchFooter };
