import React, { useState } from 'react';
import { components } from 'react-select';
import { debounce } from '../../../shared/utils/debounce';
import { client } from '../../../shared/api';
import { Select } from '../../../shared/components';
import { SEARCH_TYPE } from '../../constants';

import destination from './img/destination_icon.svg';
import './HotelsGroupSelect.scss';

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <img src={destination} style={{ marginRight: '16px' }} />
    <span>{children}</span>
  </components.ValueContainer>
);

const loadOptions = debounce(async (inputValue, callback) => {
  const value = inputValue || 'Mon';

  const { cities: citiesArr, hotels: hotelsArr } = (await client(`destination?q=${value}`)) || {};


  const cities = citiesArr?.map(city => ({ label: city.name, value: { code: city.code, type: SEARCH_TYPE.cities } })) || [];
  const hotels = hotelsArr?.map(hotel => ({ label: hotel.name, value: { code: hotel.code, type: SEARCH_TYPE.hotels } })) || [];
  const groupedOptions = [{ options: cities }, { options: hotels }];
  return callback(groupedOptions);
});

const SELECT_STYLES = {
  container: provided => ({
    ...provided,
    outline: 'none',
    border: '0',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '100%',
  }),
  control: provided => ({
    ...provided,
    borderRadius: '5px',
    backgroundColor: '#fafafa',
    border: 'none !important',
    boxShadow: 'none',
    boxSizing: 'border-box',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 20px',
    boxSizing: 'border-box',
    color: '#fafafa',
    height: '50px',
    backgroundColor: '#938884',
    borderRadius: '5px',
    fontSize: '14px',
  }),
  group: (provided) => ({
    ...provided,
    width: '50%',
    padding: '0 8px 8px',
  }),
  menu: (provided) => ({
    ...provided,
    width: '150%',
    background: '#FAFAFA',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '320px',
    display: 'flex',
  }),
  option: (provided) => ({
    ...provided,
    position: 'relative',
    marginBottom: '20px',
    padding: '8px 0 8px 34px',
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
    borderRadius: '5px',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#fafafa',
    opacity: 0.5,
  }),
  singleValue: provided => ({
    ...provided,
    color: '#fff',
  }),
};

export function HotelsGroupSelect(props) {
  const [label, setLabel] = useState(props.field?.value?.label);
  const [value, setValue] = useState(props.field?.value?.value);

  return (
    <Select
      style={{ flex: '1 1 25%' }}
      figcaption={'Destination or hotel name'}
      onChange={value => {
        setLabel(value.label);
        setValue(value.value);
        props.form.setFieldValue(props.field.name, value)
       }
      }
      onInputChange={(inputValue, { action }) => {
        switch (action) {
          case 'input-change':
            setLabel(inputValue);
            break;
          default:
            break;
        }
        return inputValue;
      }}
      label={label}
      value={value}
      loadOptions={loadOptions}
      classNamePrefix="react-select"
      placeholder="e.g. Amsterdam"
      selectStyles={SELECT_STYLES}
      onMenuClose={() => {
        if (props.field?.value?.label && label !== props.field?.value?.label) {
          setLabel(props.field?.value?.label);
        }
      }}
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        LoadingIndicator: null,
        ValueContainer,
      }}
    />
  );
}
