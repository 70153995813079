import React, { memo, useCallback, useRef, useState } from 'react';

import coefficientIcon from './img/coefficient.svg';
import { useClickOutside } from '../../../shared/hooks/useClickOutside';
import {
  selectCoefficient,
  updateCoefficient,
} from '../../modules';
import { connect } from 'react-redux';
import { debounce } from '../../../shared/utils/debounce';

function Coefficient({ coefficient, updateCoefficient }) {
  const [value, setValue] = useState(coefficient);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const updateCoefficientDebounced = debounce(nextValue => {
    updateCoefficient(nextValue);
  });

  const onContainerClick = useCallback(() => {
    setIsFocused(true);
    inputRef.current.focus();
  }, [setIsFocused]);

  useClickOutside(containerRef, () => {
    setIsFocused(false);
    if (value !== coefficient) {
      updateCoefficientDebounced(Number(value));
    }
  });

  const handleChange = useCallback(
    event => {
      const newValue = event.target.value;
      if (
        newValue.length >= 0 &&
        newValue.length <= 3 &&
        ((newValue && Number(newValue) >= 0) ||
          !newValue)
      ) {
        setValue(Number(newValue));
      }
    },
    [setValue]
  );

  return (
    <div
      style={{ display: 'inline-block', marginRight: '10px', marginBottom: '10px' }}
      onClick={onContainerClick}
      ref={containerRef}
    >
      <p
        style={{
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#fafafa',
          opacity: 0.5,
          marginBottom: '10px',
        }}
      >
        Coefficient
      </p>
      <div
        style={{
          background: '#938884',
          borderRadius: '5px',
          height: '50px',
          padding: '12px 20px',
          boxSizing: 'border-box',
          width: '102px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img src={coefficientIcon} />
        <input
          type="text"
          ref={inputRef}
          style={{
            padding: '0 0 0 8px',
            width: '100%',
            background: '#938884',
            border: 'none',
            outline: 'none',
            color: '#fafafa',
          }}
          value={isFocused ? value : `${value}%`}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

const Enhanced = memo(
  connect(
    state => ({
      coefficient: selectCoefficient(state),
    }),
    { updateCoefficient }
  )(Coefficient)
);

export { Enhanced as Coefficient };
