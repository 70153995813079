import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Contacts } from './containers/Contacts';
import { Landing } from './containers/Landing';
import { Hotels } from './containers/Hotels';
import { ProvideAuth } from './shared/auth';
import { Login } from './containers/Login';
import { Settings } from './containers/Settings';
import { Admin } from './containers/Admin';
import { Verification } from './containers/Verification';
import { IFrame } from './shared/components/IFrame';
import { HeaderNavigation } from './shared/components/HeaderNavigation';
import { MainNavigation } from './shared/components/MainHavigation';
import { getGlobalSettings } from './admin/modules';
import {AboutUs} from "./containers/AboutUs";

const iframeConfig = [
  { path: '/rent', key: 'carRent' },
  { path: '/cruises', key: 'cruises' },
  { path: '/tours', key: 'tours' },
  { path: '/flights', key: 'flights' },
]

function App({ globalSettings, getGlobalSettings }) {
  useEffect(() => {
    getGlobalSettings();
  }, [])
  return (
    <Router>
      <Switch>
        <ProvideAuth>
          <Route path="/auth" component={Login} exact/>
          <Route path="/verify" component={Verification} exact/>
          <Route path="/" exact component={Landing}/>
          <Route path="/hotels" exact component={Hotels}/>
          <Route path="/settings" exact>
            <Settings/>
          </Route>
          <Route path="/bookings" exact>
            <Admin/>
          </Route>
          <Route path="/contacts" exact component={Contacts} />
          <Route path="/about-us" exact component={AboutUs} />
          {/*{iframeConfig.map(({ path, key }) => <Route key={key} path={path} exact>*/}
          {/*  <div className="header">*/}
          {/*    <HeaderNavigation />*/}
          {/*    <IFrame src={globalSettings?.[key]} />*/}
          {/*    <MainNavigation left />*/}
          {/*  </div>*/}
          {/*</Route>)}*/}
        </ProvideAuth>
      </Switch>
    </Router>
  );
}

export default connect((state) => ({ globalSettings: state.admin.globalSettings }), { getGlobalSettings })(App);
