import { handleActions } from "redux-actions";
import {
  setCoefficient,
  setIsLoading,
  setReservations,
  setGlobalSettings,
  setPaginationInfo,
} from "./admin.actions";
import moment from "moment";

const format = "MM/DD/YYYY";
const cancellationTimeFormat = "MM/DD/YYYY HH:mm";

function adaptReservations(reservations) {
  return reservations.map((reservation) => ({
    date: {
      checkIn: moment(reservation.checkIn).format(format),
      checkOut: moment(reservation.checkOut).format(format),
    },
    price: reservation.netAmount,
    currency: reservation.currency,
    creationDate: moment(reservation.createdAt).format(format),
    description: reservation.comments,
    guest: `${reservation.firstName} ${reservation.lastName}`,
    bookingId: reservation.bookingId,
    email: reservation.email,
    source: reservation.source,
    status: reservation.status,
    nights: moment(reservation.checkOut).diff(reservation.checkIn, "days"),
    hotelName: reservation.hotelName,
    cancellationDate: reservation.cancellationPolicies?.[0]?.from
      ? moment(reservation.cancellationPolicies?.[0]?.from).format(
          cancellationTimeFormat
        )
      : null,
  }));
}

export default handleActions(
  {
    [setReservations]: (state, { payload }) => {
      return {
        ...state,
        reservations: adaptReservations(payload),
      };
    },
    [setIsLoading]: (state, { payload }) => {
      return {
        ...state,
        isLoading: payload,
      };
    },
    [setCoefficient]: (state, { payload }) => {
      return {
        ...state,
        coefficient: payload,
      };
    },
    [setPaginationInfo]: (state, { payload }) => {
      return {
        ...state,
        paginationInfo: payload,
      };
    },
    [setGlobalSettings]: (state, { payload }) => {
      return {
        ...state,
        globalSettings: payload,
      };
    },
  },
  {
    reservations: null,
    coefficient: 0,
  }
);
