import React from 'react';
import { Loader } from '../components';

export function withLoader(Component) {
  return props => {
    const { isLoading, ...rest } = props;

    return (
      <>
        <Component {...rest} />
        {isLoading && <Loader />}
      </>
    );
  };
}
