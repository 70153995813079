import React, { useState, useMemo, useEffect, useCallback } from 'react';
import ReactStars from 'react-stars';
import { useField } from 'formik';
import cn from 'classnames';

import { Button } from '../../../shared/components/Button';
import { HotelCardMap } from '../HotelsCardMap';
import { loadHotelImages, loadRooms } from '../../modules';

import styles from './HotelCard.module.scss';

import car from './img/car.svg';
import gym from './img/gym.svg';
import pool from './img/pool.svg';
import cancelPolicyIcon from './img/cancelPolicy.svg';
import { HotelCardImages } from '../HotelCardImages';
import { Reserve } from '../Reserve';
import { ReviewsItems } from '../../../shared/components/ReviewsItems';

export function HotelCard(props) {
  const { hotel, book } = props;
  const [_, form] = useField();
  const [rooms, setRooms] = useState(null);
  const [isShownRooms, setIsShownRooms] = useState(false);
  const [isShownDescription, setIsShownDescription] = useState(false);
  const [isShownMap, setIsShownMap] = useState(false);
  const [isShownImages, setIsShownImages] = useState(false);
  const [imagesList, setImagesList] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  function closeModal() {
    setIsOpen(false);
  }

  const asyncAPI = useCallback(async () => {
    const images = await loadHotelImages(hotel.code, hotel.source);
    setImagesList(images);
  }, []);

  useEffect(() => {
    asyncAPI();
  }, []);

  const handleLoadRooms = async () => {
    if (!rooms) {
      const hotelRooms = await loadRooms(
        { hotelCode: hotel.code, destinationCode: hotel.destinationCode },
          hotel.source,
          form.value,
        );

      setRooms(hotelRooms);
    }
    setIsShownRooms(!isShownRooms);
    setIsShownDescription(false);
    setIsShownMap(false);
    setIsShownImages(false);
  };

  const roomsList = useMemo(() => {
    if (!rooms) return null;

    return Object.keys(rooms).map(entry => {
      return (
        <div className={styles.room__group}>
          <div className={styles.room__group__title}>{entry}</div>
          {rooms[entry].map(room => {
            return (
              <div className={styles.room__row}>
                <div className={styles.room__row__title}>{room.board}</div>
                <div className={styles.room__row__middle}>{room.cancellationPolicy ? <p><img src={cancelPolicyIcon} />Free cancellation</p> : ''}</div>
                <div className={styles.room__row__secondary}>
                  <div className={styles.room__row__secondary__price}>
                    {room.net} EUR
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpen(true);
                      setSelectedRoom(room);
                    }}
                    className={styles.room__row__secondary__reserve}
                  >
                    Reserve
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  }, [rooms]);

  return (
    <>
      <div
        className={styles.wrapper}
        style={{
          ...((isShownRooms ||
            isShownDescription ||
            isShownMap ||
            isShownImages) && { borderBottom: 0 }),
        }}
      >
        <div className={styles.thumb}>
          <img src={hotel.thumbnail} />
        </div>
        <div className={styles.general}>
          <div>
            <div className={styles.header}>
              <h3 className={styles.title}>{hotel.name}</h3>
              <ReactStars
                count={hotel.categoryCode}
                value={hotel.categoryCode}
                edit={false}
              />
            </div>
            <div className={styles.city}>{hotel.destName}</div>
            <div className={styles.city}>{hotel.address}</div>
            <div className={styles.options}>
              {hotel.hasParking && <img src={car} />}
              {hotel.hasPool && <img src={pool} />}
              {hotel.hasGym && <img src={gym} />}
            </div>
          </div>
          <div>
            <div className={styles.tabs}>
              <div
                className={cn(styles.tabs__tab, {
                  [styles['tabs__tab--active']]: isShownDescription,
                })}
                onClick={() => {
                  setIsShownDescription(!isShownDescription);
                  setIsShownMap(false);
                  setIsShownImages(false);
                  setIsShownRooms(false);
                }}
              >
                Description
              </div>
              <div
                className={cn(styles.tabs__tab, {
                  [styles['tabs__tab--active']]: isShownMap,
                })}
                onClick={() => {
                  setIsShownDescription(false);
                  setIsShownMap(!isShownMap);
                  setIsShownImages(false);
                  setIsShownRooms(false);
                }}
              >
                Map
              </div>
              <div
                className={cn(styles.tabs__tab, {
                  [styles['tabs__tab--active']]: isShownImages,
                })}
                onClick={() => {
                  setIsShownDescription(false);
                  setIsShownMap(false);
                  setIsShownImages(!isShownImages);
                  setIsShownRooms(false);
                }}
              >
                Images
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.review}>
            <ReviewsItems amount={hotel.review} />
          </div>
          <div className={styles.type} />
          <h3 className={styles.price}>
            <span className={styles['price__title']}>From</span>
            <span className={styles['price__value']}>
              {hotel.minRate} {hotel.currency}
            </span>
          </h3>
          <div className={styles.more}>
            <Button
              type="button"
              text="More prices and boards"
              onClick={handleLoadRooms}
            />
          </div>
        </div>
      </div>
      {isShownRooms && <div>{roomsList}</div>}
      {isShownDescription && (
        <div
          style={{ fontSize: '12px', color: '1f1b1b' }}
          dangerouslySetInnerHTML={{ __html: hotel.description }}
        />
      )}
      {isShownMap && (
        <>
          <HotelCardMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `300px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            lat={hotel.latitude}
            lng={hotel.longitude}
          />
        </>
      )}
      {isShownImages && (
        <>
          <HotelCardImages images={imagesList} />
        </>
      )}
      {modalIsOpen && (
        <>
          <Reserve
            closeModal={closeModal}
            onSubmit={(values) => {
              closeModal();
              book({ ...values, ...form.value, ...selectedRoom });
            }}
            modalIsOpen={modalIsOpen}
            hotel={hotel}
            filters={form.value}
            room={selectedRoom}
          />
        </>
      )}
    </>
  );
}
