import React from 'react';
import ReactPaginate from 'react-paginate';

import previousLabel from './img/previous-page.svg';
import nextLabel from './img/next-page.svg';
import first from './img/to-start-page.svg';
import end from './img/to-the-end-page.svg';

import styles from './Pagination.module.scss';

export function PaginationUI(props) {
  const { onChange, page, total } = props;

  return (
    <div className={styles.wrapper}>
      <span className={styles.first} onClick={() => onChange({ selected: 0 })}>
        <img src={first} />
      </span>
      <ReactPaginate
        breakLabel={'...'}
        previousLabel={<img src={previousLabel} />}
        nextLabel={<img src={nextLabel} />}
        breakClassName={styles.break}
        previousClassName={styles.prev}
        previousLinkClassName={styles['prev--link']}
        nextClassName={styles.next}
        nextLinkClassName={styles['next--link']}
        pageCount={total}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onChange}
        containerClassName={styles.inner}
        subContainerClassName={'pages pagination'}
        activeClassName={styles['item--active']}
        pageClassName={styles.item}
        pageLinkClassName={styles.link}
        activeLinkClassName={styles['link--active']}
        disableInitialCallback={true}
        forcePage={page}
      />
      <span className={styles.last} onClick={() => onChange({ selected: total - 1 })}>
        <img src={end} />
      </span>
    </div>
  );
}
