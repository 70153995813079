import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from '../TopHotels/TopHotels.module.scss';

export function TopHotelCard(props) {
  const { wrapperClassName, hotel, onClick, index } = props;

  const onClickHandler = useCallback(() => {
    onClick(hotel);
  }, [hotel, onClick]);
  return (
    <div className={wrapperClassName} onClick={onClickHandler}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${
            index === 3 ? hotel.horizontalPath : hotel.verticalPath
          })`,
        }}
      />
      <div className={styles.description}>
        <div className={styles.info}>
          <h3 className={cn(styles.bold, styles.lg)}>{hotel.name},</h3>
          <span className={styles.m} style={{ marginLeft: '10px' }}>
            {hotel.country}
          </span>
        </div>
        <div className={styles.info}>
          <span className={styles.m} style={{ marginRight: '10px' }}>
            from
          </span>
          <h3 className={cn(styles.bold, styles.lg)}>
            {hotel.price} {hotel.currency}
          </h3>
          <span className={cn(styles.bold, styles.m)}>/night</span>
        </div>
      </div>
    </div>
  );
}
