import { SignIn } from './components/SignIn';
import { SignUp } from './components/SignUp';

export const CONTENT_TYPE = {
  signIn: 'signIn',
  signUp: 'signUp',
  forgot: 'forgot',
}

export const TYPE_TO_CONTENT = {
  [CONTENT_TYPE.signIn]: SignIn,
  [CONTENT_TYPE.signUp]: SignUp,
  // [CONTENT_TYPE.forgot]: Forgot,
}

export const TYPE_TO_HEIGHT = {
  [CONTENT_TYPE.signIn]: '496px',
  [CONTENT_TYPE.signUp]: '620px',
}
