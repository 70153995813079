import React from "react";
import styles from "./ReservationsTable.module.scss";
import "./ReservationsTable.scss";
import cn from "classnames";
import { upperCaseFirstLetter } from "../../../shared/utils/upperCaseFirstLetter";

export function ReservationsTable({ reservations, onCancel }) {
  return (
    <div className={styles.reservations}>
      <h2 style={{ marginBottom: "20px" }}>Admin Panel</h2>
      {reservations.map((reservation) => (
        <div key={reservation.bookingId} className={styles["reservation__row"]}>
          <div className={styles["reservation__column"]}>
            <span
              className={cn(
                styles["reservation__column__row"],
                styles["reservation__button"],
                styles[
                  `reservation__button--${reservation.status.toLowerCase()}`
                ]
              )}
            >
              {upperCaseFirstLetter(reservation.status)}
            </span>
            <span
              className={cn(
                styles["reservation__column__row"],
                styles["reservation__bold"]
              )}
            >
              {reservation.hotelName && reservation.hotelName}
            </span>
          </div>
          <div className={styles["reservation__column"]}>
            <span
              className={cn(
                styles["reservation__column__row"],
                styles["reservation__bold"]
              )}
            >
              {reservation.bookingId}
            </span>
          </div>
          <div className={styles["reservation__column"]}>
            <span
              className={cn(
                styles["reservation__column__row"],
                styles["reservation__bold"]
              )}
            >
              {upperCaseFirstLetter(reservation.guest)}
            </span>
            <span>
              <span className={cn(styles["reservation__secondary"])}>From</span>
              <span className={cn(styles["reservation__bold"])}>
                {reservation.date.checkIn} - {reservation.nights}{" "}
                {reservation.nights === 1 ? "night" : "nights"}
              </span>
            </span>
          </div>
          <div className={styles["reservation__column"]}>
            <span className={cn(styles["reservation__column__row"])}>
              <span className={cn(styles["reservation__secondary"])}>
                Confirmation
              </span>
              <span className={cn(styles["reservation__bold"])}>
                {reservation.creationDate}
              </span>
            </span>
          </div>
          <div className={styles["reservation__column"]}>
            <span
              className={cn(
                styles["reservation__column__row"],
                styles["reservation__secondary"]
              )}
            >
              Free cancellation until {reservation.cancellationDate}
            </span>
            <span className={cn(styles["reservation__column__row"])}>
              <span>
                <span>Total</span>
                <span className={cn(styles["reservation__bold"])}>
                  {reservation.price || 0}€
                </span>
              </span>
            </span>
            {reservation.status !== "CANCELLED" && (
              <button
                type="button"
                onClick={() => onCancel(reservation)}
                className={cn(
                  styles["reservation__column__row"],
                  styles["reservation__cancel"]
                )}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
