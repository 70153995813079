import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getBookings,
  cancel,
  selectReservations,
  selectIsLoading,
  getGlobalSettings,
  selectPaginationInfo,
} from '../../admin/modules';
import { Admin } from './Admin';

const mapStateToProps = (state) => ({
  reservations: selectReservations(state),
  isLoading: selectIsLoading(state),
  paginationInfo: selectPaginationInfo(state),
})

const mapDispatchToProps = {
  getBookings,
  cancel,
  getGlobalSettings,
};

const Enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Admin);

export { Enhanced as Admin };
