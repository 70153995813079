import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectContentType, updateDetails } from '../../login/modules';
import { Settings } from './Settings';

const mapStateToProps = state => ({
  contentType: selectContentType(state),
});

const mapDispatchToProps = ({
  updateDetails,
});

const Enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Settings);

export { Enhanced as Settings };
