import { connect } from 'react-redux';

import { HotelsList } from './HotelsList';
import { book, selectHotels } from '../../modules';

const mapStateToProps = state => ({
  hotels: selectHotels(state),
});

const mapDispatchToProps = ({
  book,
});

const Enhanced = connect(mapStateToProps, mapDispatchToProps)(HotelsList);

export { Enhanced as HotelsList };
