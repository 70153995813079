import React, { useState } from 'react';
import Modal from 'react-modal';
import { Redirect, useHistory } from 'react-router';

import { HeaderNavigation } from '../../shared/components/HeaderNavigation';
import { MainNavigation } from '../../shared/components/MainHavigation';
import { TYPE_TO_CONTENT, TYPE_TO_HEIGHT } from '../../login/constants';
import { useAuth } from '../../shared/auth';

import './Login.scss';

const getCustomStyles = (contentType) => ({
  content: {
    position: 'absolute',
    top: '100px',
    left: 0,
    bottom: 0,
    right: 0,
    width: '590px',
    height: TYPE_TO_HEIGHT[contentType],
    margin: 'auto',
    padding: '40px 40px',
    background: '#FAFAFA',
    border: 'none',
    'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.25)',
    'border-radius': '5px',
  }
})

export function Login({ contentType, location }) {
  const { session } = useAuth() || {};
  const [modalIsOpen, setIsOpen] = useState(true);
  const history = useHistory();

  function closeModal() {
    history.push('/');
    setIsOpen(false);
  }

  const { from } = location.state || { from: { pathname: '/' } }
  if (session) {
    return <Redirect to={from} />
  }

  const ModalContent = TYPE_TO_CONTENT[contentType];
  return (
    <div className="header">
      <HeaderNavigation/>
      {contentType && <Modal
        isOpen={modalIsOpen}
        style={getCustomStyles(contentType)}
      >
        <ModalContent onDone={closeModal} />
      </Modal>}
      <MainNavigation/>
    </div>
  );
}
