import React, { useEffect, useState } from 'react';
import { HeaderNavigation } from '../../shared/components/HeaderNavigation';
import { MainNavigation } from '../../shared/components/MainHavigation';
import { ReservationsTable } from '../../admin/components/ReservationsTable';
import styles from './Admin.module.scss';

import './Admin.css';
import Modal from 'react-modal';
import { Loader } from '../../shared/components';
import { Coefficient } from '../../admin/components/Coefficient';
import { useAuth } from '../../shared/auth';
import { IframeInput } from '../../admin/components/IframeInput';
import { BookingSearchFooter } from '../../admin/components/BookingSearchFooter';
import { DatePicker } from '../../shared/components/DatePicker';

const modalStyles = {
  content: {
    position: 'absolute',
    top: '50px',
    left: 0,
    bottom: 0,
    right: 0,
    width: '20%',
    height: '200px',
    margin: 'auto',
    background: '#FAFAFA',
    border: 'none',
    'box-shadow': '0px 4px 20px rgba(0, 0, 0, 0.25)',
    'border-radius': '5px',
  },
};

export function Admin({
  reservations,
  getBookings,
  cancel,
  isLoading,
  getGlobalSettings,
  paginationInfo,
}) {
  const auth = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [page, setPage] = useState(0);
  const [date, setDate] = useState({});

  useEffect(() => {
    if (!reservations) {
      getBookings();
      getGlobalSettings();
    }
  }, [getBookings, reservations, getGlobalSettings]);

  function onCancel(reservation) {
    setIsOpen(true);
    setReservation(reservation);
  }

  return (
    <div>
      <HeaderNavigation withLogo />
      {isLoading ? (
        <Loader />
      ) : (
        !reservation && (
          <div className={styles.content}>
            {auth.isAdmin && <Coefficient />}
            {auth.isAdmin && <IframeInput type="tours" />}
            {auth.isAdmin && <IframeInput type="flights" />}
            {auth.isAdmin && <IframeInput type="cruises" />}
            {auth.isAdmin && <IframeInput type="carRent" />}
            {auth.isAdmin && <div className={styles.picker}>
              <DatePicker field={{ value: date }} form={{ setFieldValue: (_, val) => {
                setDate(val);
                getBookings(page, val);
              }}}/>
            </div>}
            <div className={styles['content-body']}>
              <ReservationsTable
                onCancel={onCancel}
                reservations={reservations || []}
              />
            </div>
            <BookingSearchFooter onChange={(page) => {
              setPage(page);
              getBookings(page, date);
            }} {...paginationInfo} />
          </div>
        )
      )}
      <MainNavigation left />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={modalStyles}
      >
        <div>
          Cancel booking?
          <div className={styles.buttons}>
            <button
              onClick={() => {
                cancel(reservation);
                setReservation(null);
                setIsOpen(false);
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                setReservation(null);
                setIsOpen(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
