import React from 'react';
import cn from 'classnames';

import styles from './Counter.module.scss';

export function Counter({ value, onDecreaseClick, onIncreaseClick }) {
  return (
    <span className={styles.wrapper}>
      <button
        type="button"
        onClick={onDecreaseClick}
        disabled={!value}
        className={cn(styles.button, styles.decrease)}
      >
        -
      </button>
      <span className={styles.value}>{value}</span>
      <button
        type="button"
        onClick={onIncreaseClick}
        className={cn(styles.button, styles.increase)}
      >
        +
      </button>
    </span>
  );
}
