import React from 'react';

import { HeaderNavigation } from '../../shared/components/HeaderNavigation';
import { MainNavigation } from '../../shared/components/MainHavigation';

import './Landing.css';

import logo from './img/logo.png';

export function Landing() {
  return (
    <div className="header">
      <HeaderNavigation />
      <img className="logo" src={logo} alt="logo" />
      <MainNavigation />
    </div>
  );
}
