import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectContentType } from '../../login/modules';
import { Login } from './Login';

const mapStateToProps = state => ({
  contentType: selectContentType(state),
});

const Enhanced = compose(
  connect(mapStateToProps),
)(Login);

export { Enhanced as Login };
