import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Input } from '../../../../../shared/components';

import styles from '../../../common.module.scss';
import formStyles from './SignUpForm.module.scss'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
}

const REQUIRED = 'Required';

function validate(values) {
  const errors = {};

  if (!values.firstName?.trim()) {
    errors.firstName = REQUIRED;
  }

  if (!values.lastName?.trim()) {
    errors.lastName = REQUIRED
  }

  if (!values.email?.trim()) {
    errors.email = REQUIRED;
  }

  if (!values.password?.trim()) {
    errors.password = REQUIRED;
  }

  if (!values.confirmPassword?.trim()) {
    errors.confirmPassword = REQUIRED;
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Should be same!';
  }

  return errors;
}

export function SignUpForm({ onSubmit }) {
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          delete values.confirmPassword
          onSubmit(values);
        }}
        validate={validate}
      >
        {({ errors, touched }) => (
          <Form>
            <div className={styles.row}>
              <Field
                className={styles.input}
                type="firstName"
                name="firstName"
                figcaption="First Name"
                error={touched.firstName && errors.firstName}
                component={Input}
              />
              <Field
                className={styles.input}
                type="lastName"
                name="lastName"
                figcaption="Last Name"
                error={touched.lastName && errors.lastName}
                component={Input}
              />
            </div>
            <div className={styles.row}>
              <Field
                className={styles.input}
                type="email"
                name="email"
                figcaption="E-mail"
                error={touched.email && errors.email}
                component={Input}
              />
              <Field
                className={styles.input}
                type="phone"
                name="phone"
                figcaption="Phone (optional)"
                component={Input}
              />
            </div>
            <div className={styles.row}>
              <Field
                className={styles.input}
                type="password"
                name="password"
                figcaption="Password"
                error={touched.password && errors.password}
                component={Input}
              />
              <Field
                className={styles.input}
                type="confirmPassword"
                name="confirmPassword"
                figcaption="Confirm Password"
                error={touched.confirmPassword && errors.confirmPassword}
                component={Input}
              />
            </div>
            <div className={formStyles.terms}>By registering, you agree to the <a>terms and conditions</a> of our site automatically</div>
            <button className={styles.button} type="submit">Sign up</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
