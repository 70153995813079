export const selectHotels = state => state.hotels.hotels;

export const selectTopHotels = state => state.hotels.topHotels;

export const selectTotalHotels = state => state.hotels.totalElements;

export const selectTotalPages = state => state.hotels.totalPages;

export const selectPage = state => state.hotels.page;

export const selectIsLoading = state => state.hotels.isLoading;
