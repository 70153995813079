export const outcomingHotelsMapper = ({ date, filter, occupancies, ...body }) => {
  const filters = filter.filter(entry => entry.values.length);

  return {
    ...body,
    ...date,
    occupancies: occupancies.map(entry => {
      if (entry?.children?.length > 0) {
        return entry;
      }
      const { children ,...values } = entry;
      return values;
    }),
    ...(filters?.length && { filters }),
  };
};
