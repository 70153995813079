import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import styles from './MainNavigation.module.scss';

import hotels from './img/hotels-icon@2x.png';
import tours from './img/tours-icon@2x.png';
import flights from './img/flights-icon@2x.png';
import cruises from './img/cruises-icon@2x.png';
import carHire from './img/car-hire-icon@2x.png';

export function MainNavigation(props) {
  return (
    <div
      className={cn(styles.additional__menu, {
        [styles['additional__menu--vertical']]: props.left,
      })}
    >
      <NavLink
        to="/hotels"
        activeClassName={styles.active}
        className={cn(
          styles.additional__menu__hotels,
          styles.additional__menu__item,
          {
            [styles['additional__menu__item--vertical']]: props.left,
            [styles['additional__menu__item--horizontal']]: !props.left,
          }
        )}
      >
        <div className={styles.menu__icon}>
          <img className={styles.menu__img} src={hotels} />
        </div>

        <span className={styles.menu__text}>Hotels</span>
      </NavLink>
      <div
        onClick={() => window.location = props.globalSettings?.tours}
        className={cn(
          styles.additional__menu__tours,
          styles.additional__menu__item,
          {
            [styles['additional__menu__item--vertical']]: props.left,
            [styles['additional__menu__item--horizontal']]: !props.left,
          }
        )}
      >
        <div className={styles.menu__icon}>
          <img className={styles.menu__img} src={tours} />
        </div>
        <span className={styles.menu__text}>Tours</span>
      </div>
      <div
        onClick={() => window.location = props.globalSettings?.flights}
        className={cn(
          styles.additional__menu__flights,
          styles.additional__menu__item,
          {
            [styles['additional__menu__item--vertical']]: props.left,
            [styles['additional__menu__item--horizontal']]: !props.left,
          }
        )}
      >
        <div className={styles.menu__icon}>
          <img className={styles.menu__img} src={flights} />
        </div>
        <span className={styles.menu__text}>Flights</span>
      </div>
      <div
        onClick={() => window.location = props.globalSettings?.cruises}
        className={cn(
          styles.additional__menu__cruises,
          styles.additional__menu__item,
          {
            [styles['additional__menu__item--vertical']]: props.left,
            [styles['additional__menu__item--horizontal']]: !props.left,
          }
        )}
      >
        <div className={styles.menu__icon}>
          <img className={styles.menu__img} src={cruises} />
        </div>
        <span className={styles.menu__text}>Cruises</span>
      </div>
      <div
        onClick={() => window.location = props.globalSettings?.carRent}
        className={cn(
          styles.additional__menu__carhire,
          styles.additional__menu__item,
          {
            [styles['additional__menu__item--vertical']]: props.left,
            [styles['additional__menu__item--horizontal']]: !props.left,
          }
        )}
      >
        <div className={styles.menu__icon}>
          <img className={styles.menu__img} src={carHire} />
        </div>
        <span className={styles.menu__text}>Car hire</span>
      </div>
    </div>
  );
}
