import React, { useState, useCallback, useRef } from 'react';
import cn from 'classnames';

import styles from './DropDown.module.scss';
import { useClickOutside } from '../../hooks/useClickOutside';

export function DropDown(props) {
  const { size, right } = props;

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  useClickOutside(dropdownRef, closeModal);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles[`size-${size}`]]: size,
      })}
      ref={dropdownRef}
    >
      <props.components.Input onClick={toggleOpen} />
      {isOpen && (
        <div
          className={cn(styles.dropdown, {
            [styles.right]: right,
          })}
        >
          <props.components.Menu />
        </div>
      )}
    </div>
  );
}
