import React, { useEffect, useMemo } from 'react';

import { TopHotelCard } from '../TopHotelCard';

import cn from 'classnames';
import styles from './TopHotels.module.scss';
import { Field } from 'formik';

const numberToWordMap = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
};

export function TopHotels(props) {
  useEffect(() => {
    if (!props.topHotels.length) {
      props.loadTopHotels();
    }
  }, []);

  const topHotelsCards = useMemo(() => {
    return props.topHotels.map((topHotel, index) => (
        <Field name="destination">
          {({ field: { name }, form: { setFieldValue } }) => {
            return (
                <TopHotelCard
                    key={topHotel.name}
                    wrapperClassName={cn(
                        styles.cell,
                        styles[numberToWordMap[index + 1]]
                    )}
                    onClick={values => {
                      setFieldValue(name, {
                        label: values.name,
                        code: values.destination,
                        type: 'cities',
                      });
                    }}
                    hotel={topHotel}
                    index={index}
                />
            );
          }}
        </Field>
    ));
  }, [props.topHotels]);

  return (
    <div className={cn(styles.wrapper, props.className)}>{topHotelsCards}</div>
  );
}
