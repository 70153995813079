import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectIsVerified, selectVerificationInProgress, verify } from '../../login/modules';
import { Verification } from './Verification';

const mapStateToProps = state => ({
  isVerified: selectIsVerified(state),
  verificationInProgress: selectVerificationInProgress(state),
});

const mapDispatchToProps = ({
  verify,
});

const Enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Verification);

export { Enhanced as Verification };
