import { createAction } from 'redux-actions';

import { outcomingHotelsMapper } from './hotels.mapper';
import { client } from '../../shared/api';
import { SEARCH_TYPE } from '../constants';
import moment from 'moment';

export const setHotels = createAction('HOTELS::SET_HOTELS');
export const setTopHotels = createAction('HOTELS::SET_TOP_HOTELS');
export const setIsLoading = createAction('HOTELS::SET_IS_LOADING');

const searchByCitiesPath = 'searchByCity';
const searchByHotelsPath = 'searchByHotel';

const getPath = isHotelsSearch =>
  isHotelsSearch ? searchByHotelsPath : searchByCitiesPath;

export const loadHotels = values => {
  return async dispatch => {
    const { page, size, sort, destination: { value: destValue }, ...body } = values;
    const isHotelsSearch = destValue.type === SEARCH_TYPE.hotels;
    const identifierValue = isHotelsSearch
      ? [destValue.code]
      : destValue.code;
    const requestBody = {
      ...body,
      [isHotelsSearch ? 'hotels' : 'destination']: identifierValue,
    };

    const request = outcomingHotelsMapper(requestBody);
    dispatch(setIsLoading(true));

    try {
      const res = await client(
        `${getPath(isHotelsSearch)}?page=${page}&size=${size}&sort=${sort}`,
        {
          method: 'POST',
          body: request,
        }
      );
      dispatch(setHotels(res));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const loadTopHotels = () => {
  return async dispatch => {
    const topHotels = await client('top');

    dispatch(setTopHotels(topHotels));
  };
};

export const loadRooms = async (
  { hotelCode, destinationCode },
  source,
  values
) => {
  const { page, size, sort, destination, ...body } = values;
  const request = outcomingHotelsMapper({
    destination: destinationCode,
    ...body,
  });

  return await client(`search/rooms?hotelCode=${hotelCode}&source=${source}`, {
    method: 'POST',
    body: request,
  });
};

export const loadHotelImages = async (code, source) => {
  const images = await client(
    `hotels/images?hotelCode=${code}&source=${source}`
  );
  return images.map(url => ({ source: url }));
};

function adaptBody(body) {
  let reqBody = {};
  reqBody.arrivalDate = body.date.checkIn;
  let personDefaultParams = {
    childAge: 0,
    extra: true,
    code: '',
    title: '',
    email: body.creator.email,
  };
  reqBody.person = body.creator;
  reqBody.email = reqBody.person.email;
  reqBody.remark = '';
  reqBody.hotelCode = body.hotelCode;
  reqBody.source = body.sourceOrigin;
  const persons = [];
  body.persons.forEach(person => {
    persons.push({ ...personDefaultParams, ...person });
  });
  reqBody.rooms = [{ persons, roomCode: body.rateKey }];
  reqBody.nights = moment(body.date.checkOut).diff(
    moment(body.date.checkIn),
    'days'
  );

  return reqBody;
}

export const book = body => {
  return async dispatch => {
    dispatch(setIsLoading(true));
    try {
      await client('booking/create', { body: adaptBody(body) });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
