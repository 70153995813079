import React from 'react';

import { HotelsList } from '../HotelsList';
import { HotelsSearchHeader } from '../HotelsSearchHeader';
import { HotelsSearchFilter } from '../HotelsSearchFilter';
import { HotelsSearchFooter } from '../HotelsSearchFooter';

import styles from './HotelsSearch.module.scss';

export function HotelsSearch() {
  return (
    <>
      <HotelsSearchHeader />
      <div className={styles.wrapper}>
        <HotelsSearchFilter />
        <div style={{ width: '100%' }}>
          <HotelsList />
          <HotelsSearchFooter />
        </div>
      </div>
    </>
  );
}
