import React, { useState } from 'react';
import moment from 'moment';
import cn from 'classnames';

import calendar from './img/calendar_icon.svg';
import { DateRange } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.css';
import { Delimiter } from '../Delimiter';
import { DATE_TIME_FORMAT } from '../../constants/datetime';

export function DatePicker(props) {
  const [selectionRange, setState] = useState({
    focusedInput: null,
  });

  return (
    <div style={{ flex: '0 0 28%' }}>
      <span style={{ display: 'flex' }}>
        <figcaption style={{ flex: '0 0 50%' }}>From</figcaption>
        <figcaption>To</figcaption>
      </span>
      <DateRangePicker
        startDate={moment(props.field.value.checkIn)}
        startDateId="start_date_id"
        endDate={moment(props.field.value.checkOut)}
        endDateId="end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          setState(state => ({ ...state, startDate, endDate }));
          props.form.setFieldValue(props.field.name, {
            checkIn: moment(startDate).format(DATE_TIME_FORMAT),
            checkOut: moment(endDate).format(DATE_TIME_FORMAT),
          });
        }} // PropTypes.func.isRequired,
        focusedInput={selectionRange.focusedInput}
        onFocusChange={focusedInput =>
          setState(state => ({ ...state, focusedInput }))
        }
        hideKeyboardShortcutsPanel={true}
        showDefaultInputIcon={true}
        customInputIcon={<img src={calendar} alt="" />}
        verticalSpacing={0}
        readOnly
        customArrowIcon={<Delimiter />}
        renderDayContents={day => <div>{day.format('D')}</div>}
      />
    </div>
  );
}
